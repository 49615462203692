import React, {useState, useEffect} from "react";
import { BsCurrencyDollar, BsJustify } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import { Stacked, Pie, Button, LineChart, SparkLine } from "../../components";
import {
  earningData,
  medicalproBranding,
  recentTransactions,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "./DitributorConfig";
import { useStateContext } from "../../contexts/ContextProvider";
import product9 from "./product9.jpg";
import { CChart } from "@coreui/react-chartjs";
import { CChartBar } from "@coreui/react-chartjs";
import { AxesDirective } from "@syncfusion/ej2-react-charts";
import { API } from "../../config/api";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Ecommerce = () => {
  const { currentColor, currentMode } = useStateContext();
  const [orderData, setOrderData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const distId = sessionStorage.getItem("id");
  const [filterDate, setFilterDate] = useState("");

  const handleFilterChange = (event) => {
    setFilterDate(event.target.value); // The value will be in "YYYY-MM" format
  };

 useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const token = sessionStorage.getItem("myToken");
        const response = await axios.post(
          `${API}/order/graph`,
          {
            filter: "year",
            filterDate: filterDate || "2024-01", // Fallback to default if no date is selected
          },
          {
            headers: {
              "x-token": token,
              "Content-Type": "application/json",
            },
          }
        );
 
        // Ensure that response.data.data is an object with orderData property
        if (response.data.data && typeof response.data.data === "object" && Array.isArray(response.data.data.orderData)) {
          setGraphData(response.data.data.month);
        } else {
          // Handle the case when order data format is invalid
          console.error("Invalid order data format: ", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching order data: ", error);
        console.error("Error response: ", error.response);
      }
    };
 
    fetchOrderData();
  }, [filterDate]);
  // const handleOrderData = async() => {
  //   try {
  //    const response = await axios.get(`https://api.orbitmart.co.in/api/v1/order`)
  //    const orderDataFilter = response.data.data.data.filter(data => data.distributorId == distId).reverse();
  //    setOrderData(orderDataFilter)
  //   }catch(err) {console.error(err);}
  // }
  // useEffect(() => {
  //   handleOrderData()
  // },[])

  const handleOrderData = async () => {
    const token = sessionStorage.getItem("myToken");
    try {
      const response = await axios.get(
        `${API}/order/count`,
        {
          headers: {
            "x-token": token,
            "Content-Type": "application/json",
          },
        }
      );
      // const orderDataFilter = response?.data?.data
      //   .filter((data) => data?.distributorId?._id == distId)
      //   .reverse();
      //   console.log(orderDataFilter, "orderDataFilter", response.data, response.data.data, distId)
      setOrderData(response?.data?.data?.countData);

    } catch (err) {
      console.error(err);
    }
  };
 
  console.log(orderData, "orderData")


  useEffect(() => {
    handleOrderData();
  },[]);

  const generateWeekOptions = () => {
    const weeks = ["1st Week", "2nd Week", "3rd Week", "4th Week"];
    return weeks.map((week, index) => (
      <option key={index} value={index+1}>{week}</option>
    ));
  };

  const months = [
    "January", "February", "March", "April", 
    "May", "June", "July", "August", 
    "September", "October", "November", "December"
  ];
  
  return (
    <div className="mt-24">
      <div className="d-flex flex-wrap lg:flex-nowrap justify-left ">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "20px",
          }}
        >
          <div>
            <div style={{ margin: "10% 0" }}>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Total Order
                  </span>
                  <span>{orderData[0]?.TOTAL_ORDER ? orderData[0]?.TOTAL_ORDER : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    PENDING
                  </span>
                  <span>{orderData[0]?.PENDING ?? 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    PROCESSING
                  </span>
                  <span>{orderData[0]?.PROCESSING ?? 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    PACKAGING
                  </span>
                  <span>{orderData[0]?.PACKAGING ? orderData[0]?.PACKAGING : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    SHIPPING
                  </span>
                  <span>{orderData[0]?.SHIPPING ? orderData[0]?.SHIPPING : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Delivered
                  </span>
                  <span>{orderData[0]?.DELIVERED ? orderData[0]?.DELIVERED : 0}</span>
                </div>
              </div>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    COMPLETE
                  </span>
                  <span>{orderData[0]?.COMPLETED ? orderData[0]?.COMPLETED : 0}</span>
                </div>
              </div>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    RETURNED
                  </span>
                  <span>{orderData[0]?.RETURNED ? orderData[0]?.RETURNED : 0}</span>
                </div>
              </div>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    REJECT/CANCEL
                  </span>
                  <span>{`${orderData[0]?.REJECTED ? orderData[0]?.REJECTED : 0}/${orderData[0]?.CANCELLED ? orderData[0]?.CANCELLED : 0}`}</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{width: '40%'}}>
            <div style={{ display: "flex"}}>
              <div style={{ display: "flex",width: '100%', marginTop: '34px' }} className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 p-4 pt-5 m-1   bg-no-repeat bg-cover bg-center">
                <div>
                  <h1 style={{fontSize: '20px', fontWeight: "bold"}}>Order</h1>
                </div>
                <CChart
                  type="doughnut"
                  data={{
                    labels: [
                      "Total Pending",
                      "Total Processing",
                      "Total Packaging",
                      "Total Shipping",
                      "Total Complete",
                      "Total Reject",
                    ],
                    datasets: [
                      {
                        backgroundColor: ["#FDE767","#F3B95F", "#6895D2", "#00D8FF", "#41B883", "#D04848"],
                        data: [orderData[0]?.PENDING, orderData[0]?.PROCESSING, orderData[0]?.PACKAGING, orderData[0]?.SHIPPING, orderData[0]?.COMPLETED, orderData[0]?.REJECTED + orderData[0]?.CANCELLED],
                      },
                    ],
                  }}
                />
              </div>

              {/* <div class="card" style={{ width: "400px", height: "1000px"  }}> */}
              {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 p-4 pt-5 m-1   bg-no-repeat bg-cover bg-center">
                <div>
                  <h1>Sellers</h1>
                </div>
                <CChart
                  type="doughnut"
                  data={{
                    labels: [
                      "Total approved sellers ",
                      "Total sellers                ",
                      "Total pending sellers   ",
                    ],
                    datasets: [
                      {
                        backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                        data: [10, 60, 80],
                      },
                    ],
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>

        <div style={{display:'flex', justifyContent: 'center'}}>
          <div className="bg-white dark:text-black-200 dark:bg-secondary-dark-bg h-90 rounded-xl w-full lg:w-900 p-8 pt-9 m-6  bg-no-repeat bg-cover bg-center">
            {/* <div style={{display:"flex"}}>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">Oriz</option>
  <option value="2">counter sell</option>
  <option value="3">personal app</option>
</select></div><br/>

<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">Cancel</option>
  <option value="2">Pending</option>
  <option value="3">Processing</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
</div> */}
            <div style={{ display: "flex" }}>

              {/* <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Order status</label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="1">Cancel</option>
                  <option value="2">Pending</option>
                  <option value="3">Processing</option>
                </select>
              </div> */}

              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Payment status </label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  {/* <option>All</option> */}
                  {/* <option value="MALE">pending</option> */}
                  <option value="FEMALE">Complete</option>
                </select>
              </div>

              {/* <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Month/Year</label>
                <input
                  type="month"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Enter Month/Year"
                />
              </div> */}
{/* <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Week</label>
                <div style={{display: "flex", gap:"8px"}}>
                <select className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Week/Month/Year">
          <option value="week">Week</option>
          {generateWeekOptions()}
                </select>
                </div>
               
              </div> */}
 
              {/* <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Month/Year</label>
                <div style={{display: "flex", gap:"8px"}}>
                <input
                  type="month"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Enter Month/Year"
                />
               
                </div>
               
              </div> */}
               <div className="relative z-0 p-2 w-full mb-6 group">
      <label>Month/Year</label>
      <div style={{ display: "flex", gap: "8px" }}>
        <input
          type="month"
          value={filterDate}
          onChange={handleFilterChange}
          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 peer border-slate-400 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          placeholder="Enter Month/Year"
        />
      
      </div>
    </div>
            </div>
            <CChartBar
              data={{
                labels:months,
                datasets: [
                  {
                    label: "Restaurant Order Statistics",
                    backgroundColor: "#f87979",
                    // data: [orderData?.January,orderData?.February, orderData?.March, orderData?.April, orderData?.May, orderData?.June, orderData?.July, orderData?.August, orderData?.September, orderData?.October, orderData?.November, orderData?.December ?? 0],
                    data: months.map((month) => graphData?.[month] ?? 0),
                  },
                ],
              }}
              labels="months"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
