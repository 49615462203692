import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { MultiSelect } from "react-multi-select-component";
//Alert
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { API } from "../../config/api";

// const popupStyles = {
//   border: '1px solid #80808059',
//   padding: '3px 10px',
//   borderRadius: '5px',
//   backgroundColor: 'red'
// };

// const chipStyles = {
//   border: '1px solid #80808059',
//   padding: '3px 10px',
//   borderRadius: '5px',
//   backgroundColor: 'red'
// };

const AddDeliveryRate = () => {
  const navigate = useNavigate();

  //const { token } = useContext(StateContext);

  const { setValue, deliveryRateSelectMerchant } = useContext(StateContext);

  const mainPage = () => {
    navigate("/deliveryRate");
  };

  const [DeliveryAmount, setDeliveryAmount] = useState("");
  const [deliveryAmount, setdeliveryAmount] = useState("");
  const [DeliveryKMRange, setDeliveryKMRange] = useState("");
  const [DeliveryFees, setDeliveryFees] = useState("");
  const [merchantName,setMerchantName] = useState([""]);
  const [pValue, setPValue] = useState([])
  const [selected, setSelected] = useState([]);
  const [errors, setErrors] = useState({});

console.log("delivery", pValue)
  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  const id = sessionStorage.getItem('id');
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    // e.value will contain an array of selected items
    const selectedItems = e.value.map((firmName) => {
      const foundMerchant = merchantName.find(
        (merchant) => merchant?.userId?.firmName === firmName
      );
 
      return {
        firmName: firmName,
        firmId: foundMerchant ? foundMerchant.userId._id : null,
      };
    });
  setPValue(selectedItems)
    console.log(selectedItems);
  };

  console.log(deliveryRateSelectMerchant, "selectedIds:");

  // const handleChange = (selectedItems) => {
  //   const currentSelectedItem = selectedItems.value;
  //   console.log(currentSelectedItem, "currentSelectedItem")
  //     const selectedIds = currentSelectedItem.map((item) => {
  //       console.log(item, "item")
  //       const foundMerchant = merchantName.find(
  //         (merchant) => merchant?.userId?.firmName === item
  //       );
  //       return foundMerchant._id
  //     });
  //     setPValue(selectedIds)
  // }
  // console.log(pValue, "selectedIds: pValue:");
  // const handleChange = (selectedItems) => {
  //   const currentSelectedItem = selectedItems.value;
  //   if (currentSelectedItem) {
  //     const selectedIds = currentSelectedItem.map((item) => {
  //       const foundMerchant = merchantName.find(
  //         (merchant) => merchant?.firmName === item
  //       );
  //       return foundMerchant ? foundMerchant._id : null;
  //     });
  
  //     // Use the callback form of setPValue to ensure the latest state is used
  //     setPValue((prevPValue) => {
  //       // Log the previous value for debugging
  //       console.log("Previous pValue:", prevPValue);
        
  //       return selectedIds;
  //     });
  //   } else {
  //     setPValue([]);
  //   }
  // };
console.log("delivery", pValue)

  

  const fetchOrders = async() => {
    try{
      // const response = await axios.get(`https://api.orbitmart.co.in/api/v1/auth/allMerchant/${id}`)
      const response = await axios.get(`${API}/deliveryRate`)
      setMerchantName(response.data.data);
    }catch(err) {
      console.log("orderdata error",err.message);
    } 
  }

  function postData() {

    if (!validate()) {
      return;
    }
  
    console.log("postData", selected.map((merchant) => merchant.value))
    const uploadAPI = `${API}/upload`;
    const uploadData = `${API}/deliveryRate`;
    const token = sessionStorage.getItem("myToken");
    const formData = new FormData();
    console.log("formdata", formData);
    axios.post(uploadAPI, formData).then((res) => {
      console.log("upload res", res);
      var newimg = res.data.files;
  
      console.log(deliveryAmount);
     
      //console.log(pincode);
      var finalData = {
        merchantIds: selected.map((merchant) => merchant.value),
        chargeForOrderAmountBetween_1_199:DeliveryAmount,
        chargeForOrderAmountGreaterThan_500:deliveryAmount ,
        perKmChargeOver_5Km:DeliveryKMRange,
        chargeForOrderAmountBetween_200_499:DeliveryFees,
      };
      console.log("finalData", finalData);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-token": token },
        body: JSON.stringify(finalData),
      };
      fetch(uploadData, requestOptions)
        .then((res) => {
          console.log(res);
          if (res.status == 201) {
            setAlert(true);
          
            setTimeout(() => {
              mainPage();
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }
  // const handleChange = (e) => {
  //   setPValue(e.value);
  // };
  
console.log("delivery", pValue)


  useEffect(() => fetchOrders(),[]);
  const fields = { value: "_id", text: "userId" };

console.log("delivery", pValue)

const validate = () => {
  const newErrors = {};
  // if (!merchantList || isNaN(merchantList) || Number(merchantList) <= 0) {
  //   newErrors.merchantList = "Select mer";
  // }
  // if (!DeliveryAmount || isNaN(DeliveryAmount) || Number(DeliveryAmount) <= 0) {
  //   newErrors.DeliveryAmount = "Enter a valid amount between 1-199.";
  // }
  if (!DeliveryAmount) {
    newErrors.DeliveryAmount = "Enter a valid amount";
  }
  if (!DeliveryFees) {
    newErrors.DeliveryFees = "Enter a valid amount";
  }
  if (!deliveryAmount){
    newErrors.deliveryAmount = "Enter a valid amount";
  }
  if (!DeliveryKMRange) {
    newErrors.DeliveryKMRange = "Enter a valid per km charge";
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <style>
        {`
          /* Style for the MultiSelect component container */
            .custom-multi-select .options {
              width: 100%;
              display: block;
            }
          `}
          </style>
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            DeliveryRate Updated SuccessFully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="failure">
            Sorry, deliveryRate Cannot be Updated at this moment. Please try
            Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Delivery Rate
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: 80 }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          style={{ width: 80 }}
          onClick={postData}
          class="addButton"
        >
          Add
        </button>
      </div>
      {console.log(pValue, "pValue")}
      <form>
        <div className="grid xl:grid-cols-2  m:gap-2">
          {/* <div class="relative z-0 p-2 w-full mb-6 group">
          <label>Merchant List</label>
          <MultiSelectComponent
            id="chip-customization"
            placeholder="Select a Product"
            dataSource={merchantName ? merchantName.map(name => (name?.userId?.firmName || '')) : []}
            value={pValue}
            fields={fields}
            mode="Box"
            onChange={handleChange}
          popupSettings={{
            cssClass: 'custom-popup',
            style: popupStyles
          }}
          chipCustomization={{
            avatarText: 'name',
            text: 'name',
            cssClass: 'custom-chip',
            style: chipStyles
          }}
          />
        </div> */}
          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ position: "relative", zIndex: "1001" }}
          >
            <label>Merchant List</label>
            <div className="multi-select-container" style={{ zIndex: "1002" }}>
              <MultiSelect
                id="chip-customization"
                options={
                  deliveryRateSelectMerchant
                    ? deliveryRateSelectMerchant.map((merchant) => ({
                        label: merchant?.firmName || "",
                        value: merchant?._id || "",
                      }))
                    : []
                }
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                className="custom-multi-select"
                popupSettings={{
                  cssClass: "custom-popup",
                  // style: popupStyles,
                }}
                chipCustomization={{
                  avatarText: "name",
                  text: "name",
                  cssClass: "custom-chip",
                  // style: chipStyles,
                }}
              />
            </div>
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            {/* <label>Delivery Amount Range</label> */}
            <label>Charge of order amount between 1-199</label>
            <input
              type="text"
              class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="deliveryAmount"
              onChange={(e) => setDeliveryAmount(e.target.value)}
              placeholder="Enter order amount between 1-199"
            />
            {errors.DeliveryAmount && <p className="text-red-500">{errors.DeliveryAmount}</p>}

          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="deliveryamount"
              class="form-label inline-block mb-2 text-gray-700"
            >
              <label>Charge of order amount between 200-499</label>
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="DeliveryFees"
              onChange={(e) => setDeliveryFees(e.target.value)}
              placeholder="Enter order amount between 200-499"
            />
            {errors.DeliveryFees && <p className="text-red-500">{errors.DeliveryFees}</p>}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Charge of order amount greater than 500</label>
            <input
              type="text"
              class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="DeliveryAmount"
              onChange={(e) => setdeliveryAmount(e.target.value)}
              placeholder="Enter order amount greater than 500"
            />
            {errors.deliveryAmount && <p className="text-red-500">{errors.deliveryAmount}</p>}
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Per km charge over 5km</label>
            <input
              type="text"
              class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="DeliveryKMRange"
              onChange={(e) => setDeliveryKMRange(e.target.value)}
              placeholder="Enter per km charge over 5km"
            />
            {errors.DeliveryKMRange && <p className="text-red-500">{errors.DeliveryKMRange}</p>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDeliveryRate;