import { Link } from "react-router-dom";
import { AiOutlineFolderView } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import DeleteEply from "./DeleteEply";

export const gridItemView = (itemId) => (
  <div className="flex items-center  gap-2">
  <h1
    style={{ color: "#03c9d7", display: "flex" }}
    className="font-small leading-tight text-xl mt-0 mb-2 "
  >
    <Link
      style={{ marginRight: "auto" }}
      // to={`/employeewiseorders/view/${itemId._id}`}
      to={`/employeewiseorders`}

    >
      <FiEye />
    </Link>
  </h1>


  <button
    style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
    className="font-small leading-tight text-xl mt-0 mb-2 "
  >
    <DeleteEply itemId={itemId._id} />
  </button>
</div>
);

const deleteItem = (id) => {
  if (itemsData.id === id) {
    console.log(id);
  } else {
    console.log("Not Found");
  }
};

const gridPaymentStatus = (props) => (
  <button
    type="button"
    style={{ backgroundColor: "#ff5c8e", textAlign:"center",color:"black", display:"flex", position:"relative",color:"white"}}
    className="text-black py-2 px-2 capitalize rounded-3xl text-md"
  >
    {props.paymentStatus}
  </button>
);

export const itemsGrid = [
  {
    field: "_id",
    headerText: "Id",
    isPrimaryKey: true,
    textAlign: "Center",
  },
  // {
  //   field: "category",
  //   headerText: "Category",
  //   textAlign: "Center",
  // },
  {
    field: "orderNo",
    headerText: "Order No",
    textAlign: "Center",
  },
  {
    field: "date",
    headerText: "Order Date",
    textAlign: "Center",
  },
  {
    field: "userId.firstName",
    headerText: "Employee",
    textAlign: "Center",
  },
  {
    field: "customerPrice",
    headerText: "Order Amount",
    textAlign: "Center",
  },
  {
    field: "totalPrice",
    headerText: "Employee Amount",
    textAlign: "Center",
  },
  {
    field: "orderStatus",
    headerText: "OrderStatus",
    template: gridPaymentStatus,
    
    textAlign: "Center",
  },
  {
    field: "time",
    headerText: "Delivery Time",
    textAlign: "Center",
  },
  // {
  //   field: "distancekm",
  //   headerText: "Distance in KM ",
  //   textAlign: "Center",
  // },
  {
    field: "action",
    headerText: "Actions",
    textAlign: "Center",
    template: gridItemView,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  {
    id: 1,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "Active",
    OrderStatusBg: "#03C9D7",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 2,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 3,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 4,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 5,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 6,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 7,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 8,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 9,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 10,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 11,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 12,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 13,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 14,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 15,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 16,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 17,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 18,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 19,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
  {
    id: 20,
    category: "Subscription",
    orderno: "F02",
    orderdate: "2022-05-05 18:20:00",
    employee: "Ajay Bist",
    orderamount: 1500,
    employeeamount: 200,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    deliverytime: "2022-06-05 12:00:00",
    distancekm: 30,
  },
];
