import firebase from "firebase/app";
import "firebase/messaging";
 
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaVCo1GRAIH0kVLSNi6lGhoseDFhU-TWY",
  authDomain: "orbitmart-c5900.firebaseapp.com",
  projectId: "orbitmart-c5900",
  storageBucket: "orbitmart-c5900.firebasestorage.app",
  messagingSenderId: "349071815807",
  appId: "1:349071815807:web:af58b3f051fc52ceaabb4a",
  measurementId: "G-M2EEV74L4F",
};
 
firebase.initializeApp(firebaseConfig);
 
const messaging = firebase.messaging();
 
const REACT_APP_VAPID_KEY =
  "BMI04_iBNDVDDH8vN1C01dj8qDgyiee7xNdZRVIhctp7_LzBqFuwItwsfhMUz9Ss71gwTMQeUoKnh7vjTSY5Zz0";
const publicKey = REACT_APP_VAPID_KEY;
 
export const getToken = async (setTokenFound) => {
  let currentToken = "";
 
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      // setTokenFound(true);
    } else {
      // setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
 
  return currentToken;
};
 
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });