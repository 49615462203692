import React, { useEffect, useState, useContext } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";

import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { contextMenuItems, ordersGrid } from "./config/BannerConfig";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../view.css";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import DeleteBannerModel from "./DeleteBannerModel";
import { Button } from "@material-ui/core";
import { API } from "../../config/api";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";




const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "center",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", 
    textAlign: "center",
  },
}));

const Banners = () => {
  const [tDatas, setTDatas] = useState([]);
  const classes = useStyles();
  const [alert, setAlert] = useState(false);
  const [record, setRecord] = useState(false);
  const { setBanner } = useContext(StateContext);
  const [searchQuery, setSearchQuery] = useState('');
  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ["Search"];
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const editing = { allowEditing: true };

//const random = 193;



  const fetchData = async() => {
    const id = sessionStorage.getItem("id");
    const page = 1;
    const limit = 5;
    try{
     const response = await axios.get(`${API}/banner/user/${id}`)
     setTDatas(response.data);
     if (response.data.data.length == 8) {
      setRecord(true);
    }
    } catch(e){
      console.log("error", e.message);
    }
    // axios
    //   .get(`https://api.orbitmart.co.in/api/v1/banner/user/${id}`)
    //   .then((res) => {
    //     setTDatas(res.data);
    //     if (res.data.data.length == 8) {
    //       setRecord(true);
    //     }
    //   });
  };
  const itemId = tDatas.data;
  const itemEdit = (itemId) => {
    const editId = itemId._id;
    console.log(editId);
    setBanner(itemId);
  };


  const itemDelete = (itemId) => {
    const id = Object.values(itemId);
    console.log(itemId,"++++++++++++++++++++++++++++++++++++++++")
    fetch(`${API}/banner/${itemId}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        const data = await response.json();
        console.log(data);
        
        setValue(data.code);
        
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        console.log("Delete successful");
        fetchData()
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    
  };



  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPageBanner');
    if (storedPage !== null) {
      setCurrentPage(parseInt(storedPage, 10));
    }
  }, []);
 
  useEffect(() => {
    sessionStorage.setItem('currentPageBanner', currentPage.toString());
  }, [currentPage]);

  // if (value == 201) {
  //   fetchData();
  //   setValue(random);
  // }

  const navigate = useNavigate();
  const addPage = () => {
    navigate("/banner/add");
  };

  const gridAction = (itemId) => (
    <div className="flex items-center justify-center gap-2">
      <h1
        style={{ color: "#8be78b", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link style={{ marginRight: "auto" }} to="/banners/edit">
          <BsFillPencilFill onClick={() => itemEdit(itemId)} />
        </Link>
      </h1>


      {/* <h1
        style={{ color: "#03c9d7", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        
          <FiEye onClick={() => itemView(itemId)} />
      
      </h1> */}


      <button
        style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
        className="font-small leadi ng-tight text-xl mt-0 mb-2 "
        
      >
        <DeleteBannerModel itemId={itemId._id} itemDelete={itemDelete} />
      </button>
    </div>
  );
  // const gridImage=(itemId)=> {
  //   return (
  //     <img
  //       className="rounded-xl h-20 md:ml-3"
  //       src={itemId?.coverPhoto[0]}
  //       alt="order-item"
  //     />
  //   );
  // }
  const gridImage=(itemId)=> {
    return (
      <img
        className="rounded-xl h-20 border border-gray-300 shadow-md transition-transform transform hover:scale-105"
        src={itemId?.coverPhoto[0]}
        alt="order-item"
        style={{ objectFit: "cover", width: "80%", height: "150px"}}
      />
    );
  }

  const responseData = tDatas?.data || []

  const filteredData = responseData.filter(item => 
    item?.addedBy == "DISTRIBUTOR" &&
    item?.bannerName.toLowerCase().includes(searchQuery.toLowerCase() 
));
  console.log(filteredData, "filteredData filteredData")

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(responseData?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(responseData?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
   const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  };

  console.log("current page", currentPage);
    console.log("page size", pageSize);
    console.log("total data", filteredData?.length);
    console.log(Math.ceil(filteredData?.length / pageSize));

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {/* <Header  title="Banners" /> */}
      {record ? null : (
        <div class="flex space-x-2 m-4 justify-end">
          <button onClick={() => addPage()} class="addBtn">
            New Record
          </button>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: "rgb(0, 60, 126)",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
        Banners ({responseData ? filteredData.length : 0})
        </h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>
      <TableContainer className={currentPageData.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Banner Image</TableCell>
              <TableCell className={classes.cellRow}>Banner Name</TableCell>
              <TableCell className={classes.cellRow}>Location</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData.length > 0 ? (
              currentPageData.map((item, i) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell} style={{width: '50%'}}>{gridImage(item)}</TableCell>
                  <TableCell className={classes.cell}>{item?.bannerName}</TableCell>
                  <TableCell className={classes.cell}>{item?.locationName}</TableCell>
                  <TableCell className={classes.cell}>{gridAction(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {responseData?.length > 0 ? (
          <TablePagination
            count={responseData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[2]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Banners;