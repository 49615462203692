import * as React from "react";
import { useEffect, useState,useContext } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import "../../pages/view.css";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import { htmlKeyConfig } from "@syncfusion/ej2-react-richtexteditor";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const RejectPopup = ({ open, setRejectOpen, data, parentmodal }) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const {setOrderData}=useContext(StateContext)
  const API = "https://api.orbitmart.co.in/api/v1/order";

  const handleClose = async() => {
    setRejectOpen(false)
  };
  

  const handleAPI = async () => {
    const payload = {
      orderStatus: "REJECTED",
    };
    const config = { headers: { "Content-Type": "application/json","x-token":sessionStorage.getItem('myToken') } };
    await axios
      .put(`${API}/${data.props._id}`, payload,config)
      .then(async(response) => {
        const SuccessRes = response.data.status == "SUCCESS";
        if (SuccessRes) {
          await axios
       
          .get(`https://api.orbitmart.co.in/api/v1/order`)
          .then((res) => {
            let data=res.data.data
            .filter((or) => or.distributorId == sessionStorage.getItem("id"))
            .reverse().map((itemId)=>{ itemId.grandTotal=getAmount(itemId);return itemId})
            setOrderData(data);
        
          })
          setRejectOpen(false);
          parentmodal(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("rejected response error!", error);
        setRejectOpen(false);
        parentmodal(false);
      });
  };
  const getAmount = (itemId) => {
    const subtotal = itemId?.productDetails
      ?.map((pro) => {
        return pro.productId?.itemPrice * pro?.quantity;
      })
      .reduce((a, b) => a + b)||0;
    const customerDiscount = itemId?.productDetails
      ?.map((pr) => {
        if (pr?.productId?.discountType == "FIXED") {
          return parseFloat(pr?.productId?.customerDiscount);
        } else {
          return (
            (parseFloat(pr?.productId?.itemPrice) *
              parseFloat(pr?.quantity) *
              parseFloat(pr?.productId?.customerDiscount)) /
            100
          );
        }
      })
      .reduce((a, b) => a + b)||0;
    const GstLogic =
      ((subtotal - customerDiscount) *
        parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
      100||0;
    const DeliveryAmount = 0;
    const TotalAmount = (
      subtotal -
      customerDiscount +
      GstLogic +
      DeliveryAmount
    ).toFixed(0);
    return TotalAmount;
  };
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* <button
        type="button"
        style={{ marginTop: 1, alignItems: "center" }}
        onClick={handleOpen}
      >
        
        <AiFillDelete />
      </button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ color: "#ff5c8e", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <RiErrorWarningLine />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            Are you sure
          </h1>

          <h4 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            you wan't to cancel this order?
          </h4>

          <br />

          <div class="flex space-x-2 justify-center aline-center">
            <button type="button" class="backButton" onClick={handleClose}>
              No
            </button>
            <button type="button" class="addButton" onClick={handleAPI}>
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default RejectPopup;
