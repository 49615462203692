import React from "react";
import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { RiErrorWarningLine } from "react-icons/ri";

import { Link } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { render } from "react-dom";
import { Modal } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import axios from "axios";
import { API } from "../../config/api";


const style = {
  position: "absolute",
  top: "50%",
  left: "52.5%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
  width: 547,
  height: 500,
};

const AssignPopup = ({id}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {orderData, setOrderData} = useContext(StateContext)
    const [employee, setEmployee] = useState([]);
    const [filteremployee, setfilteremployee] = useState([]);
    const [emptype, setEmptype] = useState("");
    const [empId, setEmpId] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [minDateTime, setMinDateTime] = useState("");

    const handleDate = (e) => {
      const date = new Date(e);
  
    // Format date as DD-MM-YYYY
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString()
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    setDate(formattedDate);
  
    // Format time as 12-hour with AM/PM
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // const ampm = hours >= 12 ? 'PM' : 'AM';
    // const formattedHours = hours % 12 || 12; // Convert 0 hours to 12 for AM/PM
    const formattedTime = `${hours}:${minutes.toString()}:0`;
    setTime(formattedTime);
    };
  
    console.log(time, date, "time format and date format")


    useEffect(() => {
        const now = new Date();
        const formattedNow = now.toISOString().slice(0, 16);
        setMinDateTime(formattedNow);
      }, []);
    
    const handleemptype = (e) => {
   
      setEmptype(e.target.value);
      filterdata(e.target.value);
    };
    const filterdata = (data) => {
      if (data == "SELF") {
        let data = employee.filter(
          (item) => item.empStatus.toUpperCase() == "SELF"
        );
        setfilteremployee(data);
      } else if (data !== "SELF") {
        let data = employee.filter(
          (item) => item.empStatus.toUpperCase() !== "SELF"
        );
        setfilteremployee(data);
      }
    };
    const handleemp = (e) => {
      setEmpId(e.target.value);
    };
  
    const fetchEmployee = () => {
      let id = sessionStorage.getItem("id");
  
      console.log("IDName", id);
  
      fetch(`${API}/auth/employee/byDistributorId/${id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setEmployee(data.data);
        });
    };
    const navigate = useNavigate();
    const mainPage = () => {
        navigate("/Orders");
      };
      // const API = "https://api.orbitmart.co.in/api/v1/order";
    
      const handleAssign = async () => {
        const payload = {
          orderStatus: "PACKAGING",
          employeeId: empId,
          isEmployee: true,
          deliveryDate: date,
          deliveryTime: time
        };
      
    
        // add token
    
        const config = {
          headers: {
            "Content-Type": "application/json",
            "x-token": sessionStorage.getItem("myToken"),
          },
        };
        await axios
          .put(`${API}/order/${id}`, payload, config)
          .then(async(response) => {
            const SuccessRes = response.data.status == "SUCCESS";
            if (SuccessRes) {

                await axios
       
                .get(`${API}/order`)
                .then((res) => {
                  let data=res.data.data
                  .filter((or) => or.distributorId == sessionStorage.getItem("id"))
                  .reverse().map((itemId)=>{ itemId.grandTotal=getAmount(itemId);return itemId})
      
                  setOrderData(data);
              
                })
                .catch((err) => {
                  console.log("orderdata error",err.message);
                });
              console.log("handleAssign response", response);
              mainPage();
            }
          })
          .catch((error) => {
            console.log("handleAssign response error!", error);
          });
      };
    
      useEffect(() => {
        fetchEmployee();
      }, []);
    

      const getAmount = (itemId) => {
        const subtotal = itemId?.productDetails
          ?.map((pro) => {
            return pro.productId?.itemPrice * pro?.quantity;
          })
          .reduce((a, b) => a + b)||0;
        const customerDiscount = itemId?.productDetails
          ?.map((pr) => {
            if (pr?.productId?.discountType == "FIXED") {
              return parseFloat(pr?.productId?.customerDiscount);
            } else {
              return (
                (parseFloat(pr?.productId?.itemPrice) *
                  parseFloat(pr?.quantity) *
                  parseFloat(pr?.productId?.customerDiscount)) /
                100
              );
            }
          })
          .reduce((a, b) => a + b)||0;
        const GstLogic =
          ((subtotal - customerDiscount) *
            parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
          100||0;
        const DeliveryAmount = 0;
        const TotalAmount = (
          subtotal -
          customerDiscount +
          GstLogic +
          DeliveryAmount
        ).toFixed(0);
        return TotalAmount;
      };
    
    
      return (
        <div>
          <button
            type="button"
            style={{ marginTop: 1, alignItems: "center" }}
            onClick={handleOpen}
          >
            ASSIGN
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            //hideBackdrop={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <div id="EmplyDitels">
                  <h4>Assign Employee to Order :</h4>
                </div>
                <div className="EmplayBox">
                  <div className="EmplayType">
                    <label className="lablestyle" style={{color: '#000'}}>
                      Employee Type
                      <span>*</span>
                    </label>
                    <select
                      aria-label="Default select example"
                      name="categoryId"
                      value={emptype}
                      onChange={handleemptype}
                      required
                      className="EmplayeInput"
                     style={{ marginTop: '10px'}}
                    >
                      <option selected>Select Value</option>
                      {/* {employee.map((typeget) => (
                        <option value={typeget.empStatus} id={typeget._id}>
                          {typeget.empStatus}
                        </option>
                      ))} */}
                        <option value="SELF">Self</option>
              <option value="OTHER">Individual</option>
                    </select>
                  </div>

                  <br />
                  <div class="relative group" style={{ marginLeft: '80px', width: '98%'}}>
                <label
                  for="date"
                  class="form-label inline-block mb-2 text-gray-700"
                >
                  Delivery Date & Time
                </label>
                <input
                  type="datetime-local"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="date"
                  placeholder="Select Data & Time"
                  onChange={(e) => handleDate(e.target.value)}
                  min={minDateTime}
                />
              </div>
                  <br />
    
                  <div className="EmplayeDetails">
                    <label className="lablestyle1" style={{color: '#000'}}>
                      Employees
                      <span>*</span>
                    </label>
                    <select
                      aria-label="Default select example"
                      name="categoryId"
                      value={empId}
                      onChange={handleemp}
                      required
                      className="EmplayeDetailsInput"
                      style={{ marginTop: '10px', marginBottom: '20px' }}
                    >
                      <option selected>Select Value</option>
                      {filteremployee.map((typeget) => (
                        <option value={typeget._id} id={typeget._id}>
                          {typeget.firstName} &nbsp;
                          {typeget.lastName} &nbsp; &nbsp;
                          {typeget.status}
                        </option>
                      ))}
                    </select>
                  </div>
    
                  <div className="ButtonList">
                    <div className="AssignButton">
                      <button
                        type="button"
                        onClick={() => handleAssign()}
                    
                    
                      >
                        Assign
                      </button>
                    </div>
    
                    <div className="backButton1">
                      <button
                        type="button"
                        onClick={handleClose}
                      >
                        Cancel 
                      </button>
                    </div>
    
                    {/* <div className="RegectButton">
                      <button
                        type="button"
                        onClick={() => handleAssign()}
                       
                    
                      >
                        Regect
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      );
}

export default AssignPopup