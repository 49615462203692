import React, { useState, useEffect, useContext } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
// import "./tablecss.css";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Button, TablePagination } from "@material-ui/core";
import {
  itemsGrid,
  itemsData,
  contextMenuItems,
  gridItemView,
} from "./EmployeeWiseOrdersConfig";

import { BiSearch } from "react-icons/bi";
// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../config/api";
import {
  FirstPageOutlined,
  NavigateBefore,
  NavigateNext,
  LastPageOutlined,
  Pages,
} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  cell: {
    padding: "8px",
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "8px",
    textAlign: "start",
  },
}));


const formatDate = (dateString) => {
  // Parse the date string
  const parsedDate = new Date(dateString);
 
  // Check if the parsed date is valid
  if (!isNaN(parsedDate.getTime())) {
 
    // Format the date as "DD/M/YYYY"
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();
 
    return `${day}/${month}/${year}`;
  } else {
    // If parsing fails, return the original date string
    return dateString;
  }
};


const EmployeeWiseOrders = () => {
  const distId = sessionStorage.getItem("id");
  const [orderData, setOrderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("PENDING");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [TotalData,setTotalData] = useState('')


  const pendingData = orderData.filter(item => item.paymentStatus === "PENDING");


  const completedData = orderData.filter(
    (item) => item.paymentStatus === "COMPLETED"
  );
console.log(completedData, "completedData", orderData)

  const doneData = orderData.filter(item => item.paymentStatus === "DONE");

  let currentFilteredData;
  switch (selectedStatus) {
    case "PENDING":
      currentFilteredData = pendingData;
      break;
    // case "COMPLETED":
    //   currentFilteredData = completedData;
    //   break;
    case "DONE":
      currentFilteredData = doneData;
      break;


    default:
      currentFilteredData = orderData;
      break;
  }
  function fetchOrders() {
    axios
      //  
      .get(`${API}/order/web/user/${distId}?role=EMPLOYEE`)
           
      .then((res) => {
        // let data = res.data.data
        //   .filter((or) => or.distributorId == distId)
        //   .reverse()
        console.log("hello orderdata", res.data.data);
        setTotalData(res.data)
        setOrderData(res.data.data);
      })
      .catch((err) => {
        console.log("orderdata error",err.message);
      });
  }
  useEffect(() => {
    fetchOrders();
  }, []);

  console.log("TotalData", TotalData)

  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPageEmp');
    if (storedPage !== null) {
      setCurrentPage(parseInt(storedPage, 10));
    }
 
    const storedStatus = sessionStorage.getItem('selectedStatusEmply');
    if (storedStatus !== null) {
      setSelectedStatus(storedStatus);
    }
  }, []);
 
  useEffect(() => {
    sessionStorage.setItem('currentPageEmp', currentPage.toString());
  }, [currentPage]);

  // useEffect(() => {
  //   setCurrentPage(0);
  // }, [selectedStatus]);

  // const filteredData = currentFilteredData
  //   .map((item) => ({
  //     ...item,
  //     userId: {
  //       ...item?.userId,
  //       firstName: item?.userId?.firstName ?? "",
  //     },
  //   }))
  //   .filter((item) =>
  //     item?.userId?.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  const filteredData = currentFilteredData.map((item) => {
    const userName = item?.employeeId?.firstName ?? '';
    const order = item?.orderNo ?? '';
  
    return {
      ...item,
      employeeId: {
        ...item?.employeeId,
        firstName: userName,
      },
      orderNo: order,
    };
  }).filter((item) => {
    const firstNameMatches = item?.employeeId?.firstName.toLowerCase().includes(searchQuery.toLowerCase());
    const orderNoMatches = item?.orderNo?.toString().includes(searchQuery);
    const orderStatusMatches = item?.orderStatus.toLowerCase().includes(searchQuery.toLowerCase());

    return firstNameMatches || orderNoMatches || orderStatusMatches;
  });


  console.log("Search Query:", searchQuery);
  console.log("Filtered Data:", filteredData);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  };

  console.log(filteredData, "filteredData filteredData");

  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/manageItem/add");
  };

  const addFilterBtn = (status) => {
    setSelectedStatus(status);
    sessionStorage.setItem("selectedStatusEmply", status);
  };


  // Pagination handlers
  // function to handle page change
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };


  // function to handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 5));
    setCurrentPage(0); // Reset to the first page when changing page size
  };

  const classes = useStyles();
  const CustomPaginationActions = (props) => {
    const { count, page, rowsPerPage, onPageChange } = props;


    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };


    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };


    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };


    // console.log("Pending Data:", pendingData);
    // console.log("Completed Data:", completedData);
    // console.log("Done Data:", doneData);
    // console.log("Current Filtered Data:", currentFilteredData);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>


        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>


        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>


        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {/* <Header title="Employee Wise Order List"  /> */}

      {/* <h3
        style={{ color: "#03c9d7" }}
        class="font-medium leading-tight text-3xl mt-0 mb-2 "
      >
        Total Employee Earning :
        <span style={{ color: "#FF5C8E" }}> 9986.30 </span>
      </h3> */}

      

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: "rgb(0, 60, 126)",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
      <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
      Employee Wise Order List ({filteredData ? filteredData.length : 0})
          </h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>
      <div className="flex space-x-2 m-4 justify-start">
        <button
          type="button"
          onClick={() => addFilterBtn("PENDING")}
          className={`order_addBtn ${
            selectedStatus === "PENDING" ? "active_order_addBtn" : ""
          }`}
        >
          PENDING
        </button>


        {/* <button
          type="button"
          onClick={() => addFilterBtn("COMPLETED")}
          className={`order_addBtn ${
            selectedStatus === "COMPLETED" ? "active_order_addBtn" : ""
          }`}
        >
          COMPLETED
        </button> */}


        <button
          type="button"
          onClick={() => addFilterBtn("DONE")}
          className={`order_addBtn ${
            selectedStatus === "DONE" ? "active_order_addBtn" : ""
          }`}
        >
          DONE
        </button>
      </div>

      <TableContainer
        className={orderData?.length > 0 ? "table-container" : ""}
      >
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell className={classes.cellRow}>Id</TableCell> */}
              <TableCell className={classes.cellRow}>Order No</TableCell>
              <TableCell className={classes.cellRow}>Order Date</TableCell>
              <TableCell className={classes.cellRow}>Employee</TableCell>
              <TableCell className={classes.cellRow}>Order Amount</TableCell>
              <TableCell className={classes.cellRow}>Employee Amount</TableCell>
              <TableCell className={classes.cellRow}>Order Status</TableCell>
              <TableCell className={classes.cellRow}>Delivery Time</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {filteredData?.length > 0 ? (
              filteredData
                .filter((item) =>
                  selectedStatus ? item.paymentStatus === selectedStatus : true
                )
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((item, i) => (
                  <TableRow key={item._id}>
                    {/* <TableCell className={classes.cell}>{item._id}</TableCell> */}
                    <TableCell className={classes.cell}>
                      {item.orderNo}
                    </TableCell>
                    <TableCell className={classes.cell}>{formatDateTime(item?.createdAt)}</TableCell>
                    <TableCell className={classes.cell}>
                      {item?.employeeId?.firstName}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.totalPrice}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.employeeId?.deliveryPerRate}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.orderStatus}
                    </TableCell>
                    <TableCell className={classes.cell}>{formatDateTime(item?.updatedAt)}</TableCell>
                    <TableCell className={classes.cell}>
                      {gridItemView(item)}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>


        <TablePagination
          count={currentFilteredData.length}
          component="div"
          page={currentPage}
          rowsPerPage={pageSize}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5]}
          ActionsComponent={CustomPaginationActions}
        />
      </TableContainer>


      {/* <GridComponent
        id="gridcomp"
        width="auto"
        dataSource={orderData}
        allowPaging
        allowSorting
        allowExcelExport
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {itemsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Search,
            ContextMenu,
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent> */}
    </div>
  );
};
export default EmployeeWiseOrders;
